import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, Typography, Button, Paper, 
  FormControl, Select, MenuItem,
  Alert
} from '@mui/material';
import { Office, SelectedSlot, Spot } from 'shared-lib';

interface MultipleSlotSelectionProps {
  office: Office;
  selectedStartSlot: SelectedSlot;
  availableSlots: Spot[];  // Все доступные слоты на этот день
  onConfirm: (slots: SelectedSlot[], totalPrice: number) => void;
  onCancel: () => void;
}

const MultipleSlotSelection: React.FC<MultipleSlotSelectionProps> = ({
  office,
  selectedStartSlot,
  availableSlots,
  onConfirm,
  onCancel
}) => {
  const [availableEndTimes, setAvailableEndTimes] = useState<string[]>([]);
  const [endTime, setEndTime] = useState<string>('');
  const [totalPrice, setTotalPrice] = useState(selectedStartSlot.spot.prices);
  const [error, setError] = useState<string | null>(null);
  const [maxBookingInfo, setMaxBookingInfo] = useState<MaxBookingInfo | null>(null);

  const calculateDuration = useCallback((startTime: string, endTime: string): number => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    return Math.round((end.getTime() - start.getTime()) / (1000 * 60)); // разница в минутах
  }, []);

  const updateSelectedSlots = useCallback((newEndTime: string) => {
    // Находим все слоты между начальным временем и выбранным временем окончания
    const startIndex = availableSlots.findIndex(
      slot => slot.start === selectedStartSlot.spot.start
    );
    const endIndex = availableSlots.findIndex(slot => slot.end === newEndTime);
    
    if (startIndex === -1 || endIndex === -1) return;

    // Рассчитываем общую базовую цену на основе длительности
    const totalMinutes = calculateDuration(selectedStartSlot.spot.start, newEndTime);
    const totalSlots = Math.ceil(totalMinutes / office.duration.duration_without_pause);
    const basePrice = totalSlots * selectedStartSlot.spot.prices;
    
    // Применяем дневную цену, если она есть и выгоднее
    if (office.price.day_price && basePrice > office.price.day_price) {
      setTotalPrice(office.price.day_price);
    } else {
      setTotalPrice(basePrice);
    }
  }, [availableSlots, selectedStartSlot, office.duration.duration_without_pause, office.price.day_price, calculateDuration]);

  useEffect(() => {
    updateSelectedSlots(endTime);
  }, [endTime, updateSelectedSlots]);

  useEffect(() => {
    const startIndex = availableSlots.findIndex(
      slot => slot.start === selectedStartSlot.spot.start
    );
    
    if (startIndex === -1) {
      setError('Выбранное время начала недоступно');
      return;
    }

    // Генерируем возможные времена окончания с шагом duration_without_pause
    const possibleEndTimes: string[] = [];
    const duration = office.duration.duration_without_pause;
    let currentTime = new Date(`2000-01-01T${selectedStartSlot.spot.start}`);
    const endOfDay = new Date(`2000-01-01T23:59`);
    let lastAvailableEndTime: string | null = null;
    
    while (currentTime < endOfDay) {
      const nextTime = new Date(currentTime.getTime() + duration * 60000);
      const timeString = nextTime.toTimeString().slice(0, 5);
      
      const endSlotIndex = availableSlots.findIndex(slot => slot.end === timeString);
      if (endSlotIndex === -1) break;

      const slotsToCheck = availableSlots.slice(startIndex, endSlotIndex + 1);
      const allSlotsAvailable = slotsToCheck.every(slot => slot.quantity > 0);

      if (allSlotsAvailable) {
        const totalMinutes = calculateDuration(selectedStartSlot.spot.start, timeString);
        lastAvailableEndTime = timeString;
        
        console.log('Checking time:', {
          timeString,
          totalMinutes,
          minimumMinutes: office.bookingSettings.minimum_slots * office.duration.duration_without_pause,
          isLastSlot: endSlotIndex === availableSlots.length - 1,
          nextSlotAvailable: availableSlots[endSlotIndex + 1]?.quantity > 0
        });

        if (totalMinutes >= office.bookingSettings.minimum_slots * office.duration.duration_without_pause || 
            endSlotIndex === availableSlots.length - 1 ||
            !availableSlots[endSlotIndex + 1] ||
            availableSlots[endSlotIndex + 1].quantity === 0) {
          possibleEndTimes.push(timeString);
        }
        
        currentTime = nextTime;
      } else {
        break;
      }
    }

    // Если нет вариантов окончания, но есть последнее доступное время,
    // добавляем его как единственный вариант
    if (possibleEndTimes.length === 0 && lastAvailableEndTime) {
      possibleEndTimes.push(lastAvailableEndTime);
    }

    console.log('Final possible end times:', possibleEndTimes);

    setAvailableEndTimes(possibleEndTimes);

    if (possibleEndTimes.length > 0 && !endTime) {
      const firstEndTime = possibleEndTimes[0];
      setEndTime(firstEndTime);
      updateSelectedSlots(firstEndTime);
    }

    // Обновляем информацию о максимальной длительности
    const maxEndTime = possibleEndTimes[possibleEndTimes.length - 1];
    const totalMinutes = calculateDuration(selectedStartSlot.spot.start, maxEndTime);
    const totalSlots = Math.ceil(totalMinutes / office.duration.duration_without_pause);

    setMaxBookingInfo({
      endTime: maxEndTime,
      slots: totalSlots,
      duration: totalMinutes
    });
  }, [
    selectedStartSlot, 
    availableSlots, 
    office.duration.duration_without_pause, 
    office.bookingSettings.minimum_slots, 
    calculateDuration, 
    updateSelectedSlots,
    endTime,
    office.id
  ]);

  const handleEndTimeChange = (event: any) => {
    const newEndTime = event.target.value;
    setEndTime(newEndTime);
    updateSelectedSlots(newEndTime);
  };

  const handleConfirm = () => {
    const combinedSlot: SelectedSlot = {
      date: selectedStartSlot.date,
      spot: {
        ...selectedStartSlot.spot,
        end: endTime,
        prices: totalPrice
      }
    };

    onConfirm([combinedSlot], totalPrice);
  };

  const formatPrice = (price: number) => {
    return `${(price / 100).toFixed(0)} ₽`;
  };

  const formatTimeRange = (start: string, end: string) => {
    return `${start} — ${end}`;
  };

  // Функция форматирования длительности
  const formatDuration = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} минут`;
    } else if (remainingMinutes === 0) {
      if (hours === 1) return '1 час';
      if (hours >= 2 && hours <= 4) return `${hours} часа`;
      return `${hours} часов`;
    } else {
      const hoursText = hours === 1 ? 'час' : hours >= 2 && hours <= 4 ? 'часа' : 'часов';
      return `${hours} ${hoursText} ${remainingMinutes} мин`;
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Выбор времени бронирования
      </Typography>
      
      <Box sx={{ mb: 3 }}>
        <Typography variant="body2" gutterBottom>
          Начало: {selectedStartSlot.spot.start}
        </Typography>
        {maxBookingInfo && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Максимально доступное время: до {maxBookingInfo.endTime} (
            {formatDuration(calculateDuration(selectedStartSlot.spot.start, maxBookingInfo.endTime))})
          </Typography>
        )}
        {office.price.day_price > 0 && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Цена за весь день: {formatPrice(office.price.day_price)}
          </Typography>
        )}
      </Box>

      <FormControl fullWidth sx={{ mb: 3 }}>
        <Typography variant="subtitle2" gutterBottom>
          Время окончания:
        </Typography>
        <Select
          value={endTime}
          onChange={handleEndTimeChange}
        >
          {availableEndTimes.map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" gutterBottom>
          Выбранное время:
        </Typography>
        <Typography variant="body1">
          {formatTimeRange(selectedStartSlot.spot.start, endTime)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatDuration(calculateDuration(selectedStartSlot.spot.start, endTime))}
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error.replace(/слота\(ов\)/, 'времени')}
        </Alert>
      )}

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3
      }}>
        <Typography variant="subtitle1">
          Итоговая цена:
        </Typography>
        <Typography variant="h6">
          {formatPrice(totalPrice)}
          {totalPrice === office.price.day_price && (
            <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
              (цена за день)
            </Typography>
          )}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button onClick={onCancel} color="inherit">
          Отмена
        </Button>
        <Button 
          onClick={handleConfirm}
          variant="contained"
          disabled={!!error}
        >
          Продолжить
        </Button>
      </Box>
    </Paper>
  );
};

interface MaxBookingInfo {
  endTime: string;
  slots: number;
  duration: number;
}

export default MultipleSlotSelection; 