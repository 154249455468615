import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Alert, AlertTitle, Collapse, useMediaQuery, useTheme } from '@mui/material';
import { AppBar } from 'shared-lib';
import BottomAppBar from './BottomAppBar';
import { useAuth } from 'shared-lib';
import { UserService } from 'shared-lib';

const Layout: React.FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userService = UserService.getInstance();

  useEffect(() => {
    const checkVerification = async () => {
      if (user) {
        const verified = await userService.isUserVerified(user.uid);
        setIsVerified(verified);
      }
    };
    checkVerification();
  }, [user, userService]);

  const getPageInfo = () => {
    const pathname = location.pathname;
    switch (pathname) {
      case '/':
        return { title: 'Бронирование', showBackButton: false };
      case '/bookings':
        return { title: 'Мои записи', showBackButton: false };
      case '/settings':
        return { title: 'Настройки', showBackButton: false };
      case '/profile':
        return { title: 'Профиль', showBackButton: true, backTo: '/' };
      case '/settings/services':
        return { title: 'Управление услугами', showBackButton: true, backTo: '/settings' };
      default:
        if (pathname.startsWith('/bookings/')) {
          return { title: 'Детали бронирования', showBackButton: true, backTo: '/bookings' };
        }
        if (pathname.startsWith('/book/')) {
          return { title: 'Бронирование', showBackButton: false };
        }
        if (pathname.startsWith('/settings/services/')) {
          return { 
            title: pathname.includes('/new') ? 'Создание услуги' : 'Редактирование услуги', 
            showBackButton: true, 
            backTo: '/settings/services' 
          };
        }
        return { title: 'Бронирование', showBackButton: false };
    }
  };

  return (
    <>
      <AppBar getPageInfo={getPageInfo} />
      {user && isVerified === false && (
        <Collapse in={true}>
          <Alert 
            severity="warning"
            sx={{ 
              position: 'sticky',
              top: isMobile ? 56 : 64,
              zIndex: 1000,
              borderRadius: 0
            }}
          >
            <AlertTitle>Аккаунт не подтвержден</AlertTitle>
            Ваш аккаунт находится на проверке у администрации. Пожалуйста, дождитесь подтверждения для возможности бронирования.
          </Alert>
        </Collapse>
      )}
      <Box sx={{ pb: 7 }}>
        <Outlet />
      </Box>
      <BottomAppBar />
    </>
  );
};

export default Layout;
