import React, { useState, useEffect } from 'react';
import { Typography, Box, IconButton, Collapse, CircularProgress } from '@mui/material';
import { Pass } from 'shared-lib';
import KeyIcon from '@mui/icons-material/Key';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Timestamp } from 'firebase/firestore';

interface AccessCodeStatusProps {
  pass: Pass;
  bookingCreatedAt: Date;
}

const AccessCodeStatus: React.FC<AccessCodeStatusProps> = ({ pass }) => {
  const [expanded, setExpanded] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getStatusMessage = () => {
    if (pass.status === 'ACTIVE' && pass.password) {
      return pass.password;
    }

    if (pass.status === 'FAILED') {
      return 'Не удалось установить код. Пожалуйста, свяжитесь с администратором.';
    }

    const messages = [
      'Устанавливаем код доступа...',
      'Пытаемся достучаться до замка...',
      'Меняем маршрут к замку...',
      'Звоним провайдеру...',
      'Ждем, пока администратор вручную установит код...',
      'Отправляем голубя с кодом...',
      'Пробуем телепатически передать код замку...',
    ];

    const index = Math.min(Math.floor(elapsedTime / 10), messages.length - 1);
    return messages[index];
  };

  const isLoading = !pass.status || pass.status === 'PENDING';

  const formatTime = (date: Date | Timestamp) => {
    if (date instanceof Timestamp) {
      date = date.toDate();
    }
    return date.toLocaleTimeString('ru-RU', { 
      hour: '2-digit', 
      minute: '2-digit'
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <KeyIcon sx={{ color: 'text.secondary' }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isLoading && <CircularProgress size={16} sx={{ mr: 1 }} />}
          <Typography sx={{ color: 'text.primary' }}>
            {getStatusMessage()}
          </Typography>
        </Box>
        {(pass.instructions || (pass.startTime && pass.endTime)) && (
          <IconButton 
            size="small" 
            onClick={() => setExpanded(!expanded)}
            sx={{ ml: 'auto', color: 'text.secondary' }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Box>

      <Collapse in={expanded}>
        <Box sx={{ ml: 4, mt: 1 }}>
          {pass.instructions && (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'flex-start', 
              gap: 1,
              mb: 0.5
            }}>
              <InfoOutlinedIcon sx={{ color: 'text.secondary' }} fontSize="small" />
              <Typography variant="body2" color="text.secondary">
                {pass.instructions}
              </Typography>
            </Box>
          )}
          
          {pass.status === 'ACTIVE' && pass.startTime && pass.endTime && (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1 
            }}>
              <AccessTimeIcon sx={{ color: 'text.secondary' }} fontSize="small" />
              <Typography variant="body2" color="text.secondary">
                {formatTime(pass.startTime)} - {formatTime(pass.endTime)}
              </Typography>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default AccessCodeStatus;
