import React, { useState, useEffect } from 'react';
import { 
  Drawer, Box, Typography, useMediaQuery, useTheme, IconButton, 
  Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Client, Office, SelectedSlot, UserService } from 'shared-lib';
import { User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import SlotSelection from './SlotSelection';
import ConfirmationStep from './ConfirmationStep';
import { BookingService } from '../../services/BookingService';

interface TimeSlotSelectorProps {
  office: Office;
  onSelectSlot: (date: string, startTime: string, endTime: string, price: number) => void;
  open: boolean;
  onClose: () => void;
  user: User | null;
}

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = ({ office, onSelectSlot, open, onClose, user }) => {
  const [step, setStep] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlot | null>(null);
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState<boolean | null>(null);
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const bookingService = BookingService.getInstance();
  const userService = UserService.getInstance();

  useEffect(() => {
    if (open) {
      setStep(0);
      setSelectedSlot(null);
      setShowAuthDialog(false);
    }
  }, [open]);

  useEffect(() => {
    const checkVerification = async () => {
      if (user) {
        const verified = await userService.isUserVerified(user.uid);
        setIsUserVerified(verified);
      }
    };
    checkVerification();
  }, [user, userService]);

  const handleSlotSelect = async (slot: SelectedSlot) => {
    setSelectedSlot(slot);
    if (!user) {
      setShowAuthDialog(true);
    } else if (!isUserVerified) {
      setShowVerificationDialog(true);
    } else {
      setStep(1);
    }
  };

  const handleAuthDialogClose = () => {
    setShowAuthDialog(false);
    setSelectedSlot(null);
  };

  const handleLogin = () => {
    handleClose();
    navigate('/login');
  };

  const handleConfirmBooking = async (comment: string, client: Client | null) => {
    if (!selectedSlot || !user) {
      console.error("No slot selected or user not logged in");
      return;
    }

    try {
      const bookingId = await bookingService.createBooking(
        office.id,
        selectedSlot,
        user.uid,
        comment,
        client
      );
      navigate(`/bookings/${bookingId}`);
    } catch (error) {
      console.error("Error confirming booking:", error);
    }
  };

  const handleClose = () => {
    setStep(0);
    setSelectedSlot(null);
    setShowAuthDialog(false);
    onClose();
  };

  const handleBack = () => {
    setStep(Math.max(0, step - 1));
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <SlotSelection office={office} onSelectSlot={handleSlotSelect} />;
      case 1:
        return <ConfirmationStep 
          selectedSlot={selectedSlot!} 
          onConfirm={handleConfirmBooking} 
          onClose={handleClose}
        />;
      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { 
            width: isMobile ? '100%' : '600px',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {step > 0 && (
              <IconButton onClick={handleBack} aria-label="назад" sx={{ mr: 1 }}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h6">{office.name}</Typography>
          </Box>
          <IconButton onClick={handleClose} aria-label="закрыть">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          {renderStep()}
        </Box>
      </Drawer>

      <Dialog open={showAuthDialog} onClose={handleAuthDialogClose}>
        <DialogTitle>Требуется авторизация</DialogTitle>
        <DialogContent>
          <Typography>
            Для продолжения бронирования необходимо войти в систему или зарегистрироваться.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAuthDialogClose}>Отмена</Button>
          <Button onClick={handleLogin} variant="contained" color="primary">
            Войти
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showVerificationDialog} onClose={() => setShowVerificationDialog(false)}>
        <DialogTitle>Требуется подтверждение аккаунта</DialogTitle>
        <DialogContent>
          <Typography>
            Для возможности бронирования ваш аккаунт должен быть подтвержден администратором. 
            Пожалуйста, свяжитесь с администрацией для подтверждения аккаунта.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowVerificationDialog(false)}>
            Понятно
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TimeSlotSelector;
