import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, CardMedia, Typography, Button, Box, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const OfficeItem = ({ office, onSelect }) => {
    const placeholderImage = 'https://via.placeholder.com/300x200?text=Изображение+недоступно';
    const formatPrice = (price) => {
        return `${(price / 100).toFixed(0)} ₽`;
    };
    return (_jsxs(Card, { children: [_jsx(CardMedia, { component: "img", height: "200", image: office.images && office.images.length > 0 ? office.images[0] : placeholderImage, alt: office.name }), _jsxs(CardContent, { children: [_jsx(Typography, Object.assign({ gutterBottom: true, variant: "h5", component: "div" }, { children: office.name })), office.description && (_jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: office.description }))), _jsx(Box, Object.assign({ sx: { mt: 2, display: 'flex', gap: 1, alignItems: 'center' } }, { children: office.duration.duration_without_pause && (_jsxs(Box, { children: [_jsx(Chip, { icon: _jsx(AccessTimeIcon, {}), label: `${office.duration.duration_without_pause} мин`, color: "primary", variant: "outlined" }), office.duration.is_pause && (_jsxs(Typography, Object.assign({ variant: "caption", color: "text.secondary", sx: { ml: 1 } }, { children: ["(+ ", office.duration.pause, " \u043C\u0438\u043D \u043F\u0435\u0440\u0435\u0440\u044B\u0432)"] })))] })) })), _jsxs(Box, Object.assign({ sx: { mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }, { children: [office.price.base_price && (_jsx(Typography, Object.assign({ variant: "h6" }, { children: formatPrice(office.price.base_price) }))), _jsx(Button, Object.assign({ variant: "contained", color: "primary", onClick: () => onSelect(office) }, { children: "\u041E\u043D\u043B\u0430\u0439\u043D-\u0437\u0430\u043F\u0438\u0441\u044C" }))] }))] })] }));
};
export default OfficeItem;
